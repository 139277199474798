/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import { getAllSchools, insertClient, updateClient, deleteClient } from "../../actions/schools";
import '../../styles/schools.css';
import Header from "./Header";
import {useTranslation} from "react-i18next";


const Schools = ({auth: {user}, insertClient, updateClient, deleteClient, getAllSchools, schools: { profile }}) => {
    useEffect(() => {
        getAllSchools();
    },[])



    const [formData, setFormData] = useState({
        name: "",
        email: "",
        telemovel:"",
        openModal: false,
        search: "",
        countryCode: "351",
        typeModal: "novo",
        contribuinte: "",
        id: "",
    });
    const { id,typeModal, email,contribuinte, telemovel, name , openModal, search,countryCode } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});



    const onSubmit = async e => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                loading:true
            }
        );
        try{
            await insertClient({ name, email, telemovel, countryCode, contribuinte }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );

                setFormData(
                    {
                        ...formData,
                        name: "",
                        email: "",
                        telemovel:"",
                        openModal: false,
                        search: "",
                        countryCode: "351",
                        typeModal: "novo",
                        contribuinte: "",
                    }
                );



                // window.location.reload();

            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
        }



    };
    const onUpdate = async  e => {
        e.preventDefault();

        setFormData(
            {
                ...formData,
                loading:true
            }
        );
        try{
            await updateClient({ id, name, email, telemovel, countryCode, contribuinte }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );
                setFormData(
                    {
                        ...formData,
                        name: "",
                        email: "",
                        telemovel:"",
                        openModal: false,
                        search: "",
                        countryCode: "351",
                        typeModal: "novo",
                        contribuinte: "",
                    }
                );

            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
            console.log(e)
        }
    }
    const onClickModal = async e => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                openModal: openModal ? false : true,
                typeModal: "novo",
                name: "",
                email: "",
                telemovel:"",
                search: "",
                countryCode: "351",
                contribuinte: "",
            }
        );
    };
    const onDelete = async (e, id) => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                openModal:  false,
            }
        );

        try{
            await deleteClient({ id }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );

                setFormData(
                    {
                        ...formData,
                        openModal:  false ,
                        typeModal: "novo",
                        name: "",
                        email: "",
                        telemovel:"",
                        search: "",
                        countryCode: "351",
                        contribuinte: "",
                    }
                );
            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
        }
    };
    const onUpdateModal = async (e, id, name, telemovel, email, countryCode, contribuinte) => {
        e.preventDefault();
        // name, telemovel, email, countryCode
        setFormData(
            {
                ...formData,
                openModal: true,
                name: name,
                telemovel: telemovel,
                email: email,
                countryCode: countryCode,
                contribuinte: contribuinte,
                typeModal: "edit",
                id: id
            }
        );
    };


    const { t, i18n } = useTranslation()

    return user === null && profile === null ? <Fragment><div> <img src="/img/loading.gif" alt=""/></div></Fragment> :
                    <Fragment>
                        <Header />
                        <div className="dashboard-page">
                            <main>
                                <div className="header-page">
                                    <div>
                                        <h1>Sesame {t('menu.schools')}</h1>
                                    </div>
                                    <div>

                                        <button onClick={ e => onClickModal(e) }  style={{"text-transform": "uppercase"}}>
                                            {t('btn')} {t('menu.schools')}
                                        </button>

                                    </div>
                                </div>

                                <ul id="client-list">
                                    {profile && profile.allSchools.map(({_id, name}) => (
                                        <li key={_id} onClick={e => onUpdateModal(e, _id, name)}>
                                            <div>
                                                <p>
                                                    <h3>{name}</h3>
                                                </p>

                                            </div>
                                            <button onClick= { e => onDelete(e, _id)}>{t('btndel')}</button>
                                        </li>
                                    ))}
                                </ul>

                                <div className={openModal ? "new-client active" : "new-client"}>
                                        <span id="close-client-new" onClick={ e => onClickModal(e) }>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus"
                                                 className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 384 512"><path fill="currentColor"
                                                                             d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                                        </span>
                                    <form  onSubmit= { e => {typeModal === "novo" ? (
                                        onSubmit(e)
                                    ) : (
                                        onUpdate(e)
                                    )} }
                                    >

                                        {typeModal === "novo" ? (
                                            <h2>{t('insertSchool')}</h2>
                                        ) : (
                                            <h2>{t('editSchool')}</h2>
                                        )}

                                        <label htmlFor="">Name
                                            <input type="text" name="name" value={name} onChange={e=> onChange(e)} required /></label>


                                        {typeModal === "novo" ? (
                                            <button>{t('add')}</button>
                                        ) : (
                                            <button>{t('save')}</button>
                                        )}


                                    </form>
                                </div>

                            </main>
                        </div>
                    </Fragment>


};

Schools.propTypes = {
    insertClient: PropTypes.func.isRequired,
    deleteClient:  PropTypes.func.isRequired,
    getAllSchools: PropTypes.func.isRequired,
    updateClient: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    schools: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    schools: state.schools
});
export default connect(mapStateToProps, {  insertClient, deleteClient, getAllSchools, updateClient })(Schools);
