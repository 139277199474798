/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect, useLayoutEffect} from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {  insertStudents, deleteStudents, getAllStudents, updateStudents, getAllStudentsByFilter  } from "../../actions/students";
import '../../styles/schools.css';
import Header from "./Header";
import {useTranslation} from "react-i18next";
import Pagination from "./Pagination";

const Parents = ({auth: {user},  insertStudents, deleteStudents, getAllStudents, updateStudents , students: { profile }, getAllStudentsByFilter}) => {
    useEffect(() => {
        getAllStudents();
    },[]);



    const [formData, setFormData] = useState({
        name: "",
        email: "",
        telemovel:"",
        openModal: false,
        search: "",
        countryCode: "351",
        typeModal: "novo",
        contribuinte: "",
        role: "",
        school: "",
        id: "",
        nome_filter: "",
        email_filter: "",
        school_filter: "",
        professor_filter: ""
    });
    const {nome_filter, email_filter, school_filter,  id,typeModal, email,contribuinte, telemovel, name , openModal, search, countryCode, role, school } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});



    const onSubmit = async e => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                loading:true
            }
        );
        try{
            await insertStudents({ name, email, role: 1, school }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );

                setFormData(
                    {
                        ...formData,
                        name: "",
                        email: "",
                        telemovel:"",
                        openModal: false,
                        search: "",
                        countryCode: "351",
                        typeModal: "novo",
                        contribuinte: "",
                    }
                );



                // window.location.reload();

            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
        }



    };
    const onUpdate = async  e => {
        e.preventDefault();

        setFormData(
            {
                ...formData,
                loading:true
            }
        );
        try{
            await updateStudents({ id,  name, email, role, school }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );
                setFormData(
                    {
                        ...formData,
                        name: "",
                        email: "",
                        telemovel:"",
                        openModal: false,
                        search: "",
                        countryCode: "351",
                        typeModal: "novo",
                        contribuinte: "",
                    }
                );

            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
            console.log(e)
        }
    }
    const onClickModal = async e => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                openModal: openModal ? false : true,
                typeModal: "novo",
                name: "",
                email: "",
                telemovel:"",
                search: "",
                countryCode: "351",
                contribuinte: "",
            }
        );
    };
    const onDelete = async (e, id) => {
        e.preventDefault();
        setFormData(
            {
                ...formData,
                openModal:  false,
            }
        );

        try{
            await deleteStudents({ id }).then((res) =>{
                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,
                            alert: "Dados Incorrectos tente novamente"
                        }
                    );

                setFormData(
                    {
                        ...formData,
                        openModal:  false ,
                        typeModal: "novo",
                        name: "",
                        email: "",
                        telemovel:"",
                        search: "",
                        countryCode: "351",
                        contribuinte: "",
                    }
                );
            });
        }catch(e){
            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Dados Incorrectos tente novamente"
                }
            );
        }
    };
    const { t, i18n } = useTranslation()
    const onUpdateModal = async (e, id,  name, email, role, school) => {
        e.preventDefault();
        // name, telemovel, email, countryCode
        setFormData(
            {
                ...formData,
                openModal: true,
                name: name,
                email,
                role,
                school,
                typeModal: "edit",
                id: id
            }
        );
    };

    /* PAGINATION */
    const [currentPage, setCurrentPage] = useState(1);
    const [postsPerPage, setPostsPerPage] = useState(20);
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    let resultados = profile ? profile.allParents ? profile.allParents : [] : [];
    const currentPosts = resultados.slice(indexOfFirstPost,indexOfLastPost);
    const paginate = (e,pageNumber) => {
        e.preventDefault()
        setCurrentPage(pageNumber)
    }

    /* FILTER  */
    const onChangeText = async (e) => {
        setCurrentPage(1)

        let nomeData = nome_filter, emailData = email_filter, schoolID = school_filter;

        if(school_filter !== e.target.value && "school_filter" === e.target.name) {
            schoolID = e.target.value;
            setFormData({ ...formData, [e.target.name]: e.target.value});
        }

        if(nome_filter !== e.target.value && "nome_filter" === e.target.name) {
            nomeData = e.target.value;
            emailData = "";
            setFormData({...formData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["email_filter"]: ""
            });
        }

        if(email_filter !== e.target.value && "email_filter" === e.target.name) {
            emailData = e.target.value;
            nomeData = "";
            setFormData({...formData,
                [e.target.name]: e.target.value,
                ["loading"]: true,
                ["nome_filter"]: ""
            });
        }

        try {
            await getAllStudentsByFilter({ nome_filter: nomeData, email_filter: emailData, school: schoolID, professor: "" });
        } catch (e){

        }
    };

    return user === null && profile === null ? <Fragment><div> <img src="/img/loading.gif" alt=""/></div></Fragment> :
        <Fragment>
            <Header />
            <div className="dashboard-page">
                <main>
                    <div className="header-page">
                        <div>
                            <h1>Sesame {t('menu.parents')} </h1>
                        </div>

                        <div className="filter-container">
                            <label htmlFor="">
                                Nome:
                                <input type="text" name="nome_filter"  value={nome_filter} id=""  onChange={ e => onChangeText(e)}  />
                            </label>

                            <label htmlFor="">
                                E-mail:
                                <input type="text" name="email_filter"  value={email_filter} id=""  onChange={ e => onChangeText(e)}  />
                            </label>

                            {user && user.level === "0" ? (
                                <label htmlFor="">{t('label.school')}
                                    <select  name="school_filter" id="" value={school_filter} onChange={e=> onChangeText(e)}   required>
                                        <option value="">Select School</option>

                                        {profile && profile.allSchools.map(({_id, name}) => (
                                            <option value={_id}>{name}</option>

                                        ))}
                                    </select>
                                </label>
                            ) : (
                                 <Fragment></Fragment>
                            )}
                        </div>


                        <div>

                            <button onClick={ e => onClickModal(e) }  style={{"text-transform": "uppercase"}}>
                                {t('btn')} {t('menu.parents')}
                            </button>

                        </div>
                    </div>

                    <ul id="client-list">
                        {currentPosts.map(({_id, name, email, level, school_id}) => (
                            <li key={_id} onClick={e => onUpdateModal(e, _id, name, email, level, school_id)}>
                                <div>
                                    <p>
                                        <h3>{name}</h3>
                                        <h3>{t('label.role')}: {level === "1" ? "Parent" : level === "3" ? "Student" : "-"}</h3>
                                        <h3>{t('label.school')}: {profile.allSchools.map((school) => (<span>

                                                {school._id === school_id ? school.name  : " "}

                                            </span>
                                        ))}</h3>
                                    </p>

                                </div>
                                <button onClick= { e => onDelete(e, _id)}>{t('btndel')}</button>
                            </li>
                        ))}
                    </ul>
                    <Pagination postsPerPAge={postsPerPage} totalPosts={resultados.length} paginate={paginate} currentPage={currentPage}/>

                    <div className={openModal ? "new-client active" : "new-client"}>
                                        <span id="close-client-new" onClick={ e => onClickModal(e) }>
                                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="plus"
                                                 className="svg-inline--fa fa-plus fa-w-12" role="img" xmlns="http://www.w3.org/2000/svg"
                                                 viewBox="0 0 384 512"><path fill="currentColor"
                                                                             d="M376 232H216V72c0-4.42-3.58-8-8-8h-32c-4.42 0-8 3.58-8 8v160H8c-4.42 0-8 3.58-8 8v32c0 4.42 3.58 8 8 8h160v160c0 4.42 3.58 8 8 8h32c4.42 0 8-3.58 8-8V280h160c4.42 0 8-3.58 8-8v-32c0-4.42-3.58-8-8-8z"></path></svg>
                                        </span>
                        <form  onSubmit= { e => {typeModal === "novo" ? (
                            onSubmit(e)
                        ) : (
                            onUpdate(e)
                        )} }
                        >

                            {typeModal === "novo" ? (
                                <h2>{t('insertParent')}</h2>
                            ) : (
                                <h2>{t('editParent')}</h2>
                            )}

                            <label htmlFor="">Name
                                <input type="text" name="name" value={name} onChange={e=> onChange(e)} required /></label>

                            <label htmlFor="">E-mail
                                <input type="text" name="email" value={email} onChange={e=> onChange(e)} required /></label>




                            {user && user.level === "0" ? (
                                <label htmlFor="">{t('label.school')}
                                    <select  name="school" id="" value={school} onChange={e=> onChange(e)}  required>
                                        <option value="">Select {t('label.school')}</option>

                                        {profile && profile.allSchools.map(({_id, name}) => (
                                            <option value={_id}>{name}</option>

                                        ))}
                                    </select>
                                </label>
                            ) : (
                                <label htmlFor="" style={{display: "none"}}>{t('label.school')}
                                    <select  name="school" id="" value={user ? user.school_id: ""} onChange={e=> onChange(e)}   required hidden={true}>
                                        <option  value={user ? user.school_id: ""}>Select {t('label.school')}</option>


                                    </select>
                                </label>
                            )}
                            {typeModal === "novo" ? (
                                <button>{t('add')}</button>
                            ) : (
                                <button>{t('save')}</button>
                            )}


                        </form>
                    </div>

                </main>
            </div>
        </Fragment>


};

Parents.propTypes = {
    insertStudents: PropTypes.func.isRequired,
    deleteStudents:  PropTypes.func.isRequired,
    getAllStudents: PropTypes.func.isRequired,
    updateStudents: PropTypes.func.isRequired,
    getAllStudentsByFilter: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    students: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,
    students: state.students
});
export default connect(mapStateToProps, {  insertStudents, deleteStudents, getAllStudents, updateStudents, getAllStudentsByFilter })( Parents );
