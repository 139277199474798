/**
 * Created by danielsilva on 13/04/2020.
 */
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import {
    GET_STUDENTS_LIST,
    ERROR_STUDENTS_LIST,
    API_LINK
} from "./types";


// Get Featured Video

export const getAllStudents = () => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    try  {
        const res = await axios.get(API_LINK +"/api/students");

        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response, status: err.response}
        });
    }
};

// Get Featured Video

export const insertStudents = ({ name, email, role, school, professor  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { name, email, role, school, professor };

    try  {
        const res = await axios.post(API_LINK + "/api/students/new", body, config);
        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const updateStudents = ({ id, name, email, role, school ,professor  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id, name, email, role, school,professor };

    try  {
        const res = await axios.post(API_LINK + "/api/students/update", body, config);
        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const resetStudents = ({ id  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id };

    try  {
        const res = await axios.post(API_LINK + "/api/students/resetPassword", body, config);
        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const emailReportStudents = ({ id, reportID  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id, reportID };

    try  {
        const res = await axios.post(API_LINK + "/api/students/downloadReports", body, config);

        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const deleteStudents = ({id }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {id };

    try  {
        const res = await axios.post(API_LINK +"/api/students/delete", body, config);
        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getAllStudentsByFilter = ({ nome_filter, email_filter, school, professor }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { nome_filter, email_filter, school, professor };

    console.log({nome_filter, email_filter, school, professor})
    try  {
        const res = await axios.post(API_LINK +"/api/students/filter" , body, config);

        dispatch({
            type:  GET_STUDENTS_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STUDENTS_LIST,
            payload: { msg: err.response, status: err.response}
        });
    }
};
