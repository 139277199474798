import React, {Fragment, useState } from "react";
import {Link, Redirect} from "react-router-dom";
import {connect, Provider} from "react-redux";
import PropTypes from "prop-types";

import {logout, login} from "../../actions/auth";


import '../../styles/landingpage.css';



const Landing = ({auth: {isAuthenticated}, logout, login}) => {

    const [submitApp, setSubmitApp] = useState(false);

    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password:"",
        loading: false,
        alert: false
    });
    const { name,email,password,loading, alert } = formData;
    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value});

    const onSubmit = async e => {
        e.preventDefault();
        setFormData({ ...formData,  loading:true});
        setSubmitApp(true)

        try{
            await login({ email, password }).then((res) =>{
                setSubmitApp(false);

                if(!res)
                    setFormData(
                        {
                            ...formData,
                            loading:false,

                        }
                    );

            }, (err) => {
                setSubmitApp(false);
                setFormData(
                    {
                        ...formData,
                        loading:false,
                        alert: "Incorrect Login Credentials"
                    }
                );
            });
        }catch(e){
            setSubmitApp(false);

            setFormData(
                {
                    ...formData,
                    loading:false,
                    alert: "Incorrect Login Credentials"
                }
            );
        }

    };
    // Redirect if Logged in
    if(!loading && isAuthenticated)
        return <Redirect to="/dashboard" />


    return (
        <Fragment>
            <div className="landingpagecolor">
            <div className="landingpage-container">

                <form  onSubmit={ e => onSubmit(e)} className={`${loading ? "loading" : " "}`}  >
                    <div>
                        <img src="./img/logo-placeholder.png" alt="" className={"logo-form"}/>
                        <label htmlFor="" >E-mail
                            <input type="email" name="email" value={email} onChange={e=> onChange(e)} required /></label>
                        <label htmlFor="">Password
                            <input type="password" name="password" value={password} onChange={e=> onChange(e)} required/></label>


                        {submitApp ? (
                            <div  style={{height: "auto"}}> </div>
                        ): (<button type="submit">Login</button>)}
                        {alert ? (<div className="error-alert" style={{color: "red", height: "auto", marginTop: "10px"}}>{alert}</div>) : ""}

                        <div className={"img-sesame-container"}>
                            <img src="./img/ErasmusCo.png" alt="" className={"logo-form"}/>
                            <img src="./img/EU-flag.png" alt="" className={"logo-form"}/>
                        </div>

                    </div>
                </form>

            </div>
            </div>
        </Fragment>
    )
};

Landing.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool,

};
const mapStateToProps = state => ({
    auth: state.auth,
    isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps, { logout, login })(Landing);
