import axios from "axios";
import {
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED,
    AUTH_ERROR,
    LOGOUT,
    RECOVERY_PASS_SUCCESS,
    RECOVERY_PASS_FAIL,
    RESET_PASS_SUCCESS,
    RESET_PASS_FAIL,
    TYPE_MARCACAO_SUCCESS,
    TYPE_MARCACAO_FAIL,
    TYPELIST_MARCACAO_SUCCESS,
    TYPELIST_MARCACAO_FAIL, ERROR_MARCACOES_LIST,
    API_LINK
} from "./types";
import setAuthToken from "../utils/setAuthToken";
import {Router} from "react-router-dom";
// Load User
export const loadUser = () => async dispatch => {
    if (localStorage.token)
        setAuthToken(localStorage.token);

    try {
        const res = await axios.get(API_LINK +"/api/users/load");

        dispatch({
            type: USER_LOADED,
            payload: res.data
        })
    } catch (err) {
        console.log({err})
        dispatch({
            type: AUTH_ERROR,
        })
    }
}

// Register User
export const register = ({name, email, password, telemovel}) => async dispatch => {
    // @TODO needs to pass school level and user level


    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {name, email, password, telemovel};

    try {
        const res = await axios.post(API_LINK + "/api/users", body, config);
        dispatch({
            type: REGISTER_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        const errors = err.response.data.errors;
        console.error(err.response.data);
        if (errors) {
            errors.forEach(error => dispatch(error.msg, "danger"));
        }


        dispatch({
            type: REGISTER_FAIL
        })
    }
};

// Register User
export const login = ({email, password}) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }

    // const body = JSON.stringify({name, email, password});
    const body = {email, password};

    try {
        const res = await axios.post( API_LINK + "/api/users/login", body, config);

        dispatch({
            type: LOGIN_SUCCESS,
            payload: res.data
        });
        dispatch(loadUser());
    } catch (err) {
        console.log(err)
        const errors = err.response.data.errors;

        if (errors) {
            errors.forEach(error => dispatch(error.msg));
        }


        dispatch({
            type: LOGIN_FAIL
        })
    }
}


// LOGOUT
export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}

