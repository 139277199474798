import React, {Fragment,useState, useEffect,useContext, useLayoutEffect } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {logout} from "../../actions/auth";
import '../../styles/dashboard.css';

import Header from "./Header";
import moment from "moment";


const Dashboard = ({auth: {user}, logout}) => {
    useEffect(() => {

    },[]);

    const [dashboardData, setDashboardData] = useState({



    });





    const { } = dashboardData;



    return user === null  ? <Fragment><div> <img src="/img/loading.gif" alt=""/></div></Fragment> :
                    <Fragment>
                        <Header />

                    </Fragment>

};

Dashboard.propTypes = {
    logout: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
    auth: state.auth,

});
export default connect(mapStateToProps, {logout})(Dashboard);
