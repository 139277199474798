import { combineReducers } from "redux";
import auth from "./auth";
import schools from "./schools";
import staff from "./staff";
import students from "./students";

export default combineReducers({
    auth,
    schools,
    staff,
    students
});
