/**
 * Created by danielsilva on 13/04/2020.
 */
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import {
    GET_CLIENT_LIST,
    ERROR_CLIENT_LIST,
    POST_CLIENT_LIST,
    ERROR_POST_CLIENT_LIST,
    SEARCH_CLIENT_NAME,
    ERROR_SEARCH_CLIENT_NAME,
    API_LINK
} from "./types";


// Get Featured Video

export const getAllSchools = () => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    try  {
        const res = await axios.get(API_LINK + "/api/schools");
        dispatch({
            type:  GET_CLIENT_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_CLIENT_LIST,
            payload: { msg: err.response, status: err.response}
        });
    }
};

// Get Featured Video

export const insertClient = ({  name,  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { name, };

    try  {
        const res = await axios.post(API_LINK +"/api/schools/new", body, config);
        dispatch({
            type:  GET_CLIENT_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_CLIENT_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const updateClient = ({ id,   name    }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {id,  name  };

    try  {
        const res = await axios.post(API_LINK +"/api/schools/update", body, config);
        dispatch({
            type:  GET_CLIENT_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_CLIENT_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const deleteClient = ({id }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {id };

    try  {
        const res = await axios.post(API_LINK +"/api/schools/delete", body, config);
        dispatch({
            type:  GET_CLIENT_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_CLIENT_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
