/**
 * Created by danielsilva on 13/04/2020.
 */
import axios from "axios";
import setAuthToken from "../utils/setAuthToken";

import {
    GET_STAFF_LIST,
    ERROR_STAFF_LIST, GET_STUDENTS_LIST, ERROR_STUDENTS_LIST,API_LINK

} from "./types";


// Get Featured Video

export const getAllStaff = () => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    try  {
        const res = await axios.get(API_LINK + "/api/staff");
        dispatch({
            type:  GET_STAFF_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STAFF_LIST,
            payload: { msg: err.response, status: err.response}
        });
    }
};

export const getAllStaffByFilter = ({ nome_filter, email_filter, school, professor }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { nome_filter, email_filter, school, professor };

    try  {
        const res = await axios.post(API_LINK +"/api/staff/filter" , body, config);

        dispatch({
            type:  GET_STAFF_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STAFF_LIST,
            payload: { msg: err.response, status: err.response}
        });
    }
};


// Get Featured Video

export const insertStaff = ({ name, email, role, school  }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { name, email, role, school };

    try  {
        const res = await axios.post(API_LINK +"/api/staff/new", body, config);
        dispatch({
            type:  GET_STAFF_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STAFF_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const updateStaff = ({ id, name, email, role, school   }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = { id, name, email, role, school };

    try  {
        const res = await axios.post(API_LINK + "/api/staff/update", body, config);
        dispatch({
            type:  GET_STAFF_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STAFF_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
export const deleteStaff = ({id }) => async dispatch => {
    const config = {
        header: {
            "Content-Type": "application/json"
        }
    }
    const body = {id };

    try  {
        const res = await axios.post(API_LINK + "/api/staff/delete", body, config);
        dispatch({
            type:  GET_STAFF_LIST,
            payload: res.data
        });

    }  catch (err){
        dispatch({
            type:  ERROR_STAFF_LIST,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
