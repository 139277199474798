/**
 * Created by danielsilva on 13/04/2020.
 */
import {
    GET_STAFF_LIST,
    ERROR_STAFF_LIST,
    SEARCH_CLIENT_NAME,
    ERROR_SEARCH_CLIENT_NAME
} from "../actions/types";

const initialState = {
    profile: null,
    clientsearch: { allSchools: []},
    loading:true,
    error: {}
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case SEARCH_CLIENT_NAME:
            return {
                ...state,
                clientsearch: payload,
                loading: false
            };
        case GET_STAFF_LIST:
            return {
                ...state,
                profile: payload,
                loading: false
            };

        case ERROR_STAFF_LIST:
        case ERROR_SEARCH_CLIENT_NAME:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;

    }
}
