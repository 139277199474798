/**
 * Created by danielsilva on 10/04/2020.
 */
import React, {Fragment, useState, useEffect } from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import '../../styles/dashboard.css';
import { useTranslation } from 'react-i18next'

import {logout} from "../../actions/auth";
import Select from "react-dropdown-select";




const Header = ({auth: { user }, logout }) => {
    const { t, i18n } = useTranslation()

    const onSignOut = async e => {
        e.preventDefault();


        try{
            await logout()
        }catch(e){

        }

    };

    const changeLanguage = (event) => {
        i18n.changeLanguage(event.target.value)
    }

    return user === null ? <Fragment><div> <img src="/img/loading.gif" alt=""/></div></Fragment> :

        <Fragment>
            <header>
                <div className="container-header">
                    <Link to={"/dashboard"}> <img src="./img/logo-placeholder.png" alt="" className={"logo-header"}/></Link>


                    <ul>
                        <div className="custom-select"  >
                            <select  className={"select-lang"} onChange={changeLanguage}>
                                <option value="en">EN</option>
                                <option value="fr">FR</option>
                                <option value="ita">ITA</option>
                                <option value="ger">GER</option>
                            </select>
                        </div>


                        {user && user.level.toString() === "0" ? (
                            <Fragment>
                                <li className="active"><Link to={"/students"}> {t('menu.students')}</Link></li>
                                <li className="active"><Link to={"/parents"}>{t('menu.parents')}</Link></li>
                                <li className="active"><Link to={"/schools"}>{t('menu.schools')}</Link></li>
                                <li className="active"><Link to={"/staff"}>{t('menu.staff')}</Link></li>
                            </Fragment>
                        ) : ""}

                        {user && user.level.toString() === "2" ? (
                            <Fragment>
                                <li className="active"><Link to={"/students"}> {t('menu.students')}</Link></li>
                                <li className="active"><Link to={"/parents"}>{t('menu.parents')}</Link></li>
                            </Fragment>
                        ) : ""}

                        {user && user.level.toString() === "4" ? (
                            <Fragment>
                                <li className="active"><Link to={"/students"}> {t('menu.students')}</Link></li>
                                <li className="active"><Link to={"/parents"}>{t('menu.parents')}</Link></li>
                                <li className="active"><Link to={"/staff"}>{t('menu.staff')}</Link></li>
                            </Fragment>
                        ) : ""}


                        <button onClick={ (e) => onSignOut(e) }>LOGOUT</button>
                    </ul>
                </div>
            </header>
        </Fragment>

};

Header.propTypes = {
    logout: PropTypes.func,
    alterNameUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
    auth: state.auth
});
export default connect(mapStateToProps, {logout })( Header );
