import React, {Fragment, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import './styles/global.css';
import './i18n'

import Landing from "./components/layout/LandingPage";
import Dashboard from "./components/layout/Dashboard";
import Schools from "./components/layout/Schools";

import Staff from "./components/layout/Staff";
import Students from "./components/layout/Students";
import Parents from "./components/layout/Parents";



import PrivateRoute from "./components/routing/PrivateRoute";

// @Title REDUX
import { Provider } from "react-redux";
import store from "./store";
import setAuthToken from "./utils/setAuthToken";
import { loadUser } from "./actions/auth";

if(localStorage.token)
  setAuthToken(localStorage.token);

const App= () => {

  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return(
      <Provider store={store}>
        <Router>
          <Fragment>
            <Route exact path="/" component={Landing} />
              <Route exact path="/login" component={Landing} />

            <Switch>
            {/*  <Route exact path="/login" component={Login} />*/}

                <PrivateRoute exact path="/dashboard" component={Students} />
                <PrivateRoute exact path="/schools" component={Schools} />
                <PrivateRoute exact path="/students" component={Students} />
                <PrivateRoute exact path="/parents" component={Parents} />

                <PrivateRoute exact path="/staff" component={Staff} />


            </Switch>
          </Fragment>
        </Router>

      </Provider>
  )};

export default App;
